import React, {useState} from 'react';
import Password from "./components/Password";
import {isPasswordRight} from "./utils/password";
import Translator from "./components/Translator";


const App = () => {
    const [password, setPassword]= useState(isPasswordRight(window.localStorage.getItem('PASSWORD')) ? 'password': null);

    const onGetPassword = pwd => {
        window.localStorage.setItem('PASSWORD', pwd);
        setPassword(pwd);
    };

    return password ? <div><Translator /></div> : <Password onPassword={onGetPassword} />;
}

export default App;
