

export default (text) => {
    const options = {
        method: 'POST',
        headers: {
            'Ocp-Apim-Subscription-Key': '9ccd8af1ff7c4a0495db8b4e72512474',
            'Ocp-Apim-Subscription-Region': 'eastus',
            'Content-type': 'application/json',
            // 'X-ClientTraceId': v4(),
        },
        body:JSON.stringify([{
            'text': text
        }]),
    };

    //

    return fetch('https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=en&from=hu&category=9ea2900c-8d7f-424a-bfa0-53a4be74102d-LAW', options)
        .then(response => response.json());

}
