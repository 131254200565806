import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {Typography} from "@material-ui/core";
import {isPasswordRight} from "../utils/password";


const Password = ({onPassword}) => {
    const [password, setPassword] = useState('');

    const onFormSubmit = () => {
        if (isPasswordRight(password)) {
            onPassword(password);
        }
    }

    return <Container maxWidth="sm">
        <Typography variant="h2" gutterBottom>
            Password
        </Typography>
        <form onSubmit={onFormSubmit}>
            <TextField type="password" value={password} onChange={({target: {value}}) => setPassword(value)} fullWidth
                       variant="outlined"/>
        </form>
    </Container>;
}


export default Password;
