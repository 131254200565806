import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import translate from "../utils/translate";
import CircularProgress from "@material-ui/core/CircularProgress";

const Translator = () => {
    const [source, setSource] = useState('');
    const [loading, setLoading] = useState(false);
    const [destination, setDestination] = useState('');

    const onTranslate = async () => {
        try {
            setDestination('');
            setLoading(true);
           const result =  await translate(source);
           setDestination(result[0].translations[0].text);
        } catch (e) {
            setDestination(JSON.stringify(e.error));
        } finally {
            setLoading(false);
        }
    }


    return <div style={{display:'flex',padding: "20px", height: '100vh'}}>
        <TextField value={source} onChange={({target: {value}}) => setSource(value)} fullWidth multiline rows={30}  label="Magyar"  variant="outlined" inputProps={{maxLength: 10000}}  />
        <div style={{padding: '20px'}}>
            {!!loading && <CircularProgress />}
            {!loading && <Button variant="contained" onClick={onTranslate} color="primary">Translate</Button>}
        </div>
        <TextField value={destination} fullWidth multiline rows={30}  label="English" variant="outlined"  />
    </div>;
}

Translator.propTypes = {};
Translator.defaultProps = {};


export default Translator;
